import React, { Component } from "react";
import Helmet from "react-helmet";
import Header from "../components/header";
import ReCAPTCHA from "react-google-recaptcha";
import LayoutWrapper from "../components/layout";
import { Message, Input, Textarea } from "../lib/getMessage";

import tick from "./images/green-tick.svg";
import WithProviders from "../components/WithProviders";

export const CAPTCHA_SITE_KEY = "6LfvRb4UAAAAAM6vbv9m4JE9jRcsmngJeF0wbiAo";

class Form extends Component {
  constructor(props) {
    super();
    this.state = {
      status: "IDLE", // Can be anything from ['IDLE' ,'SUBMITTING', 'FAILED', 'SUBMITTED']
    };
    this.submitForm = this.submitForm.bind(this);
    this._reCaptchaRef = React.createRef();
    this.handleCaptcha = this.handleCaptcha.bind(this);
    this.checkStatus = this.checkStatus.bind(this);
  }

  handleCaptcha(value) {
    this.setState({
      captcha: value,
    });
  }

  submitForm(e) {
    e.preventDefault();
    this.setState({
      status: "SUBMITTING",
    });
    let formData = new FormData(e.target);
    formData.delete("g-recaptcha-response");
    formData.append("captcha", this.state.captcha);
    let url = "http://internal.staging.zopsmart.com/mail.php";
    if (window.location.host === "www.zopsmart.com") {
      url = "https://internal.zopsmart.com/mail.php";
    }
    let headers = new Headers({
      Authorization: "Basic YWRtaW46ZzBAbFAwJHQ=",
    });

    window
      .fetch(url, {
        method: "POST",
        headers: headers,
        body: formData,
      })
      .catch((error) => {
        this.setState({
          status: "FAILED",
        });
        console.error(error);
      })
      .then((response) => {
        response.text().then((text) => {
          if (!text) {
            this.setState({
              status: "SUBMITTED",
            });
          } else {
            this.setState({
              status: "FAILED",
            });
          }
        });
      });
  }

  checkStatus() {
    let { captcha, status } = this.state;

    return !Boolean(captcha) || status === "SUBMITTING";
  }

  componentDidMount() {
    this.setState({ load: true });
  }

  render() {
    const { load } = this.state || {};
    const disabled = this.checkStatus();
    const { direction } = this.props;
    const contactForm = (
      <div className="contact-form">
        <h2>
          <Message dictKey="write.us" />
        </h2>
        <p>
          <Message dictKey="write.us.description" />
        </p>
        <form onSubmit={this.submitForm} method="POST" id="contact-form">
          {this.state.status === "FAILED" && (
            <p className="error">
              <Message dictKey="submit.form.error" />
              <a href="mailto:info@zopsmart.com">info@zopsmart.com</a>
            </p>
          )}
          <Input
            name="name"
            placeholderKey="products.footer.fullname"
            required
            aria-label="name"
          />
          <Input
            name="phone"
            className={direction === "rtl" ? "ph-no-rtl" : ""}
            placeholderKey="products.footer.mobilenumber"
            type="tel"
            required
            aria-label="number"
          />
          <Input
            name="email"
            placeholderKey="products.footer.emailaddress"
            type="email"
            required
            aria-label="email"
          />
          <Textarea
            name="message"
            placeholderKey="products.footer.message"
            aria-label="message"
          />
          {load && (
            <ReCAPTCHA
              sitekey={CAPTCHA_SITE_KEY}
              theme="light"
              className="captcha"
              onChange={this.handleCaptcha}
              ref={this._reCaptchaRef}
              onExpired={() => this.setState({ captcha: "" })}
            />
          )}
          <button
            aria-label="submit"
            className="cta-link"
            type="submit"
            disabled={disabled}
          >
            {this.state.status === "SUBMITTING" ? (
              <Message dictKey="submitting" />
            ) : (
              <Message dictKey="submit" />
            )}
          </button>
        </form>
      </div>
    );
    const successMessage = (
      <div className="contact-form tc">
        <img src={tick} alt="" width="75" height="75" loading="lazy" />
        <h2>
          <Message dictKey="thank.you" />
        </h2>
        <p>
          <Message dictKey="will.get.in.touch" />
        </p>
      </div>
    );
    return this.state.status === "SUBMITTED" ? successMessage : contactForm;
  }
}

const Location = ({ isDirectionRTL }) => (
  <div className="location">
    <div>
      <h4>
        <Message dictKey="address" />
      </h4>
      <p>
        <Message dictKey="zs.address" />
      </p>
    </div>
    <div className="contact">
      <h4>
        <Message dictKey="mobile" />
      </h4>
      <ul>
        <li>
          <a
            className={`contact-link phone-india ${
              isDirectionRTL && "contact-link-rtl phone-india-rtl"
            }`}
            href="tel:+918041126521"
          >
            +91 80411 26521
          </a>
        </li>
        <li>
          <a
            className={`contact-link phone-uk ${
              isDirectionRTL && "contact-link-rtl phone-uk-rtl"
            }`}
            href="tel:+442382029871"
          >
            +44 23820 29871
          </a>
        </li>
        <li>
          <a
            className={`contact-link phone-us ${
              isDirectionRTL && "contact-link-rtl phone-us-rtl"
            }`}
            href="tel:+18582076278"
          >
            +1 858 207 6278
          </a>
        </li>
      </ul>
      <h4>
        <Message dictKey="email.id" />
      </h4>
      <ul>
        <li>
          <a
            className={`contact-link email-link ${
              isDirectionRTL && "contact-number-rtl email-link-rtl"
            }`}
            href="mailto:info@zopsmart.com"
          >
            info@zopsmart.com
          </a>
        </li>
      </ul>
    </div>
  </div>
);

const Contact = (props) => {
  const { language, updateLanguage, direction, location } = props;
  const isDirectionRTL = direction === "rtl";
  return (
    <LayoutWrapper
      direction={direction}
      language={language}
      location={location}
    >
      <Helmet title="Contact ZopSmart" />
      <Header
        current="contact"
        language={language}
        updateLanguage={updateLanguage}
        direction={direction}
      />
      <section className="contact-us">
        <section
          className={`contact-banner ${isDirectionRTL && "contact-banner-rtl"}`}
        >
          <div className={`wrap ${isDirectionRTL && "wrap-rtl"}`}>
            <h1 className="">
              <Message dictKey="footer.contact.us" />
            </h1>
            <p className="">
              <Message dictKey="contact.us.description" />
            </p>
          </div>
        </section>
        <section className="about-contact" id="contact">
          <div className="wrap">
            <Form direction={direction} />
            <div className="location-container">
              <iframe
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3888.8424770109295!2d77.64291691482143!3d12.917843990891168!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bae14843322933b%3A0x674e42544a89fc77!2sZopSmart+Technologies+Pvt.+Ltd.!5e0!3m2!1sen!2sin!4v1517302106215"
                frameBorder="0"
                title="google-map-conatiner"
              />
              <Location isDirectionRTL={isDirectionRTL} />
            </div>
          </div>
        </section>
      </section>
    </LayoutWrapper>
  );
};

export default WithProviders(Contact);
